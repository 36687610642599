.container {
  position: fixed;
  top: 0;
  left: 0;
  display: grid;
  justify-items: center;
  align-content: center;
  background-color: var(--blue14);
  padding: 10px 40px;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.transparent {
  background-color: var(--blue14-transparent);
}

.logo {
  width: 100%;
  max-width: 320px;
  margin-bottom: 50px;
}

@media only screen and (max-width: 480px) {
  .logo {
    margin-bottom: 30px;
  }
}
